import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import ReactPlayer from "react-player"
import { motion } from "framer-motion"
import {
  BlockContent,
  Features,
  Button,
  Heading,
  Link,
  RelatedProducts,
  Form,
  Section,
  Tag,
  SEO,
} from "components"

const ProductTemplate = ({ data, location }) => {
  const {
    title,
    productColor,
    department,
    categories = [],
    markets = [],
    solutions = [],
    image,
    _rawDescription,
    _rawApplication,
    _rawInstructions,
    _rawBenefits,
    _rawFeatures,
    videoUrl,
  } = data.sanityProduct

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  }

  return (
    <>
      <SEO title={title} />

      <Section bgImage={image} overlay>
        <div className="grid lg:grid-cols-12 gap-16 items-center relative">
          <div className="space-y-4 col-span-7">
            <motion.div
              variants={item}
              transition="easeInOut"
              className="text-white"
            >
              <Tag
                to={`/${department.slug.current}`}
                colorClass={department.slug.current}
                children={department.title}
              />
            </motion.div>

            <motion.div
              variants={item}
              transition="easeInOut"
              className="text-white"
            >
              <Heading className="text-white" level="1" children={title} />
            </motion.div>

            <motion.div
              variants={item}
              transition="easeInOut"
              className="text-white"
            >
              <div className="text-gray-400">
                Indicado para uso em{" "}
                {markets.map(({ title, slug }, index) => (
                  <React.Fragment key={slug.current}>
                    <Link
                      to={`/produtos-para-${slug.current}`}
                      className="underline text-white inline-block font-display transform hover:-translate-y-1"
                      key={slug.current}
                    >
                      {title}
                    </Link>
                    {index + 1 === markets.length ? "" : ", "}
                  </React.Fragment>
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      </Section>

      {videoUrl && (
        <Section className="pb-0">
          <motion.div
            className="content"
            variants={item}
            transition="easeInOut"
          >
            <div className="aspect-ratio-16/9 relative">
              <div className="absolute inset-0 border-b-4 border-accent-500 rounded-sm overflow-hidden shadow-soft">
                <ReactPlayer url={videoUrl} width="100%" height="100%" />
              </div>
            </div>
          </motion.div>
        </Section>
      )}

      <Section>
        <div className="grid lg:grid-cols-12 gap-16">
          <div className="lg:col-span-7">
            <div>
              <Heading level="3">{`Sobre o ${title}`}</Heading>
              <BlockContent blocks={_rawDescription} />
            </div>
            <div>
              <Heading level="3">Aplicação</Heading>
              <BlockContent blocks={_rawApplication} />
            </div>

            <div>
              <Heading level="3">Instruções</Heading>
              <BlockContent blocks={_rawInstructions} />
            </div>

            <div>
              <Heading level="3">Benefícios</Heading>
              <BlockContent blocks={_rawBenefits} />
            </div>
          </div>
          <div className="lg:col-span-5 space-y-6">
            <Form
              name="contato-comercial-produto"
              messageField
              location={location.pathname}
            />

            <Features
              data={_rawFeatures}
              color={productColor && productColor.hex}
            />

            <div>
              <Heading level="5">Categorias</Heading>

              <div>
                {categories.map(({ title, slug }) => (
                  <React.Fragment key={slug.current}>
                    <Tag
                      to={`/categoria/${slug.current}`}
                      colorClass="bg-gradient-tl-blue bg-2x"
                      className="text-shadow text-xs mb-1"
                      children={title}
                    />{" "}
                  </React.Fragment>
                ))}
              </div>
            </div>

            <div>
              <Heading level="5">Soluções</Heading>

              <div>
                {solutions.map(({ title, slug }) => (
                  <React.Fragment key={slug.current}>
                    <Tag
                      to={`/solucoes/${slug.current}`}
                      colorClass="bg-gradient-tl-blue bg-2x"
                      className="text-shadow text-xs mb-1"
                      children={title}
                    />{" "}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Section>

      <RelatedProducts
        background="bg-gray-100"
        data={data.relatedProducts.edges}
        limit={8}
      />
    </>
  )
}

ProductTemplate.propTypes = {
  data: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
}

export default ProductTemplate

export const Input = ({ id, placeholder, type = "text" }) => (
  <label htmlFor={id}>
    <span className="hidden">{placeholder}</span>
    <input
      id={id}
      name={id}
      type={type}
      className="mb-2 outline-none w-full px-4 py-2 border-gray-300 border rounded-sm flex items-center placeholder-gray-500 focus:text-primary-500 focus:border-primary-500"
      placeholder={placeholder}
    />
  </label>
)

export const query = graphql`
  query GET_PRODUCT($slug: String, $categories: [String]) {
    sanityProduct(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      image {
        asset {
          fluid(maxWidth: 500, maxHeight: 500) {
            ...GatsbySanityImageFluid
          }
        }
      }
      videoUrl
      productColor {
        hex
      }
      _rawDescription
      department {
        title
        slug {
          current
        }
      }
      categories {
        title
        slug {
          current
        }
      }
      markets {
        title
        slug {
          current
        }
      }
      solutions {
        title
        slug {
          current
        }
      }
      _rawApplication
      _rawInstructions
      _rawBenefits
      _rawFeatures
    }
    relatedProducts: allSanityProduct(
      filter: { categories: { elemMatch: { title: { in: $categories } } } }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          image {
            asset {
              fluid(maxWidth: 300, maxHeight: 300) {
                ...GatsbySanityImageFluid
              }
            }
          }
          department {
            title
            slug {
              current
            }
          }
        }
      }
    }
  }
`
